export const fallbackCountries = [
	{
		id: "11258999068719808",
		name: "Andorra",
		iso3166_a2: "AD",
		dial_code: "376"
	},
	{
		id: "11258999068719809",
		name: "United Arab Emirates",
		iso3166_a2: "AE",
		dial_code: "971"
	},
	{
		id: "11258999068719810",
		name: "Afghanistan",
		iso3166_a2: "AF",
		dial_code: "93"
	},
	{
		id: "11258999068719811",
		name: "Antigua and Barbuda",
		iso3166_a2: "AG",
		dial_code: "1"
	},
	{
		id: "11258999068719812",
		name: "Anguilla",
		iso3166_a2: "AI",
		dial_code: "1"
	},
	{
		id: "11258999068719813",
		name: "Albania",
		iso3166_a2: "AL",
		dial_code: "355"
	},
	{
		id: "11258999068719814",
		name: "Armenia",
		iso3166_a2: "AM",
		dial_code: "374"
	},
	{
		id: "11258999068719815",
		name: "Angola",
		iso3166_a2: "AO",
		dial_code: "244"
	},
	{
		id: "11258999068719816",
		name: "Antarctica",
		iso3166_a2: "AQ",
		dial_code: "672"
	},
	{
		id: "11258999068719817",
		name: "Argentina",
		iso3166_a2: "AR",
		dial_code: "54"
	},
	{
		id: "11258999068719818",
		name: "American Samoa",
		iso3166_a2: "AS",
		dial_code: "1"
	},
	{
		id: "11258999068719819",
		name: "Austria",
		iso3166_a2: "AT",
		dial_code: "43"
	},
	{
		id: "11258999068719820",
		name: "Australia",
		iso3166_a2: "AU",
		dial_code: "61"
	},
	{
		id: "11258999068719821",
		name: "Aruba",
		iso3166_a2: "AW",
		dial_code: "297"
	},
	{
		id: "11258999068719822",
		name: "Åland Islands",
		iso3166_a2: "AX",
		dial_code: "358"
	},
	{
		id: "11258999068719823",
		name: "Azerbaijan",
		iso3166_a2: "AZ",
		dial_code: "994"
	},
	{
		id: "11258999068719824",
		name: "Bosnia and Herzegovina",
		iso3166_a2: "BA",
		dial_code: "387"
	},
	{
		id: "11258999068719825",
		name: "Barbados",
		iso3166_a2: "BB",
		dial_code: "1"
	},
	{
		id: "11258999068719826",
		name: "Bangladesh",
		iso3166_a2: "BD",
		dial_code: "880"
	},
	{
		id: "11258999068719827",
		name: "Belgium",
		iso3166_a2: "BE",
		dial_code: "32"
	},
	{
		id: "11258999068719828",
		name: "Burkina Faso",
		iso3166_a2: "BF",
		dial_code: "226"
	},
	{
		id: "11258999068719829",
		name: "Bulgaria",
		iso3166_a2: "BG",
		dial_code: "359"
	},
	{
		id: "11258999068719830",
		name: "Bahrain",
		iso3166_a2: "BH",
		dial_code: "973"
	},
	{
		id: "11258999068719831",
		name: "Burundi",
		iso3166_a2: "BI",
		dial_code: "257"
	},
	{
		id: "11258999068719832",
		name: "Benin",
		iso3166_a2: "BJ",
		dial_code: "229"
	},
	{
		id: "11258999068719833",
		name: "Saint Barthélemy",
		iso3166_a2: "BL",
		dial_code: "590"
	},
	{
		id: "11258999068719834",
		name: "Bermuda",
		iso3166_a2: "BM",
		dial_code: "1"
	},
	{
		id: "11258999068719835",
		name: "Brunei Darussalam",
		iso3166_a2: "BN",
		dial_code: "673"
	},
	{
		id: "11258999068719836",
		name: "Bolivia",
		iso3166_a2: "BO",
		dial_code: "591"
	},
	{
		id: "11258999068719837",
		name: "Bonaire, Sint Eustatius and Saba",
		iso3166_a2: "BQ",
		dial_code: "599"
	},
	{
		id: "11258999068719838",
		name: "Brazil",
		iso3166_a2: "BR",
		dial_code: "55"
	},
	{
		id: "11258999068719839",
		name: "Bahamas",
		iso3166_a2: "BS",
		dial_code: "1"
	},
	{
		id: "11258999068719840",
		name: "Bhutan",
		iso3166_a2: "BT",
		dial_code: "975"
	},
	{
		id: "11258999068719841",
		name: "Botswana",
		iso3166_a2: "BW",
		dial_code: "267"
	},
	{
		id: "11258999068719842",
		name: "Belarus",
		iso3166_a2: "BY",
		dial_code: "375"
	},
	{
		id: "11258999068719843",
		name: "Belize",
		iso3166_a2: "BZ",
		dial_code: "501"
	},
	{
		id: "11258999068719844",
		name: "Canada",
		iso3166_a2: "CA",
		dial_code: "1"
	},
	{
		id: "11258999068719845",
		name: "Cocos (Keeling) Islands",
		iso3166_a2: "CC",
		dial_code: "61"
	},
	{
		id: "11258999068719846",
		name: "Democratic Republic of the Congo",
		iso3166_a2: "CD",
		dial_code: "243"
	},
	{
		id: "11258999068719847",
		name: "Central African Republic",
		iso3166_a2: "CF",
		dial_code: "236"
	},
	{
		id: "11258999068719848",
		name: "Congo",
		iso3166_a2: "CG",
		dial_code: "242"
	},
	{
		id: "11258999068719849",
		name: "Switzerland",
		iso3166_a2: "CH",
		dial_code: "41"
	},
	{
		id: "11258999068719850",
		name: "Cook Islands",
		iso3166_a2: "CK",
		dial_code: "682"
	},
	{
		id: "11258999068719851",
		name: "Chile",
		iso3166_a2: "CL",
		dial_code: "56"
	},
	{
		id: "11258999068719852",
		name: "Cameroon",
		iso3166_a2: "CM",
		dial_code: "237"
	},
	{
		id: "11258999068719853",
		name: "China",
		iso3166_a2: "CN",
		dial_code: "86"
	},
	{
		id: "11258999068719854",
		name: "Colombia",
		iso3166_a2: "CO",
		dial_code: "57"
	},
	{
		id: "11258999068719855",
		name: "Costa Rica",
		iso3166_a2: "CR",
		dial_code: "506"
	},
	{
		id: "11258999068719856",
		name: "Cuba",
		iso3166_a2: "CU",
		dial_code: "53"
	},
	{
		id: "11258999068719857",
		name: "Cabo Verde",
		iso3166_a2: "CV",
		dial_code: "238"
	},
	{
		id: "11258999068719858",
		name: "Curaçao",
		iso3166_a2: "CW",
		dial_code: "599"
	},
	{
		id: "11258999068719859",
		name: "Christmas Island",
		iso3166_a2: "CX",
		dial_code: "61"
	},
	{
		id: "11258999068719860",
		name: "Cyprus",
		iso3166_a2: "CY",
		dial_code: "357"
	},
	{
		id: "11258999068719861",
		name: "Czech Republic",
		iso3166_a2: "CZ",
		dial_code: "420"
	},
	{
		id: "11258999068719862",
		name: "Germany",
		iso3166_a2: "DE",
		dial_code: "49"
	},
	{
		id: "11258999068719863",
		name: "Djibouti",
		iso3166_a2: "DJ",
		dial_code: "253"
	},
	{
		id: "11258999068719864",
		name: "Denmark",
		iso3166_a2: "DK",
		dial_code: "45"
	},
	{
		id: "11258999068719865",
		name: "Dominica",
		iso3166_a2: "DM",
		dial_code: "1"
	},
	{
		id: "11258999068719866",
		name: "Dominican Republic",
		iso3166_a2: "DO",
		dial_code: "1"
	},
	{
		id: "11258999068719867",
		name: "Algeria",
		iso3166_a2: "DZ",
		dial_code: "213"
	},
	{
		id: "11258999068719868",
		name: "Ecuador",
		iso3166_a2: "EC",
		dial_code: "593"
	},
	{
		id: "11258999068719869",
		name: "Estonia",
		iso3166_a2: "EE",
		dial_code: "372"
	},
	{
		id: "11258999068719870",
		name: "Egypt",
		iso3166_a2: "EG",
		dial_code: "20"
	},
	{
		id: "11258999068719871",
		name: "Western Sahara",
		iso3166_a2: "EH",
		dial_code: "212"
	},
	{
		id: "11258999068719872",
		name: "Eritrea",
		iso3166_a2: "ER",
		dial_code: "291"
	},
	{
		id: "11258999068719873",
		name: "Spain",
		iso3166_a2: "ES",
		dial_code: "34"
	},
	{
		id: "11258999068719874",
		name: "Ethiopia",
		iso3166_a2: "ET",
		dial_code: "251"
	},
	{
		id: "11258999068719875",
		name: "Finland",
		iso3166_a2: "FI",
		dial_code: "358"
	},
	{
		id: "11258999068719876",
		name: "Fiji",
		iso3166_a2: "FJ",
		dial_code: "679"
	},
	{
		id: "11258999068719877",
		name: "Falkland Islands",
		iso3166_a2: "FK",
		dial_code: "500"
	},
	{
		id: "11258999068719878",
		name: "Micronesia",
		iso3166_a2: "FM",
		dial_code: "691"
	},
	{
		id: "11258999068719879",
		name: "Faroe Islands",
		iso3166_a2: "FO",
		dial_code: "298"
	},
	{
		id: "11258999068719880",
		name: "France",
		iso3166_a2: "FR",
		dial_code: "33"
	},
	{
		id: "11258999068719881",
		name: "Gabon",
		iso3166_a2: "GA",
		dial_code: "241"
	},
	{
		id: "11258999068719882",
		name: "United Kingdom",
		iso3166_a2: "GB",
		dial_code: "44"
	},
	{
		id: "11258999068719883",
		name: "Grenada",
		iso3166_a2: "GD",
		dial_code: "1"
	},
	{
		id: "11258999068719884",
		name: "Georgia",
		iso3166_a2: "GE",
		dial_code: "995"
	},
	{
		id: "11258999068719885",
		name: "French Guiana",
		iso3166_a2: "GF",
		dial_code: "594"
	},
	{
		id: "11258999068719886",
		name: "Guernsey",
		iso3166_a2: "GG",
		dial_code: "44"
	},
	{
		id: "11258999068719887",
		name: "Ghana",
		iso3166_a2: "GH",
		dial_code: "233"
	},
	{
		id: "11258999068719888",
		name: "Gibraltar",
		iso3166_a2: "GI",
		dial_code: "350"
	},
	{
		id: "11258999068719889",
		name: "Greenland",
		iso3166_a2: "GL",
		dial_code: "299"
	},
	{
		id: "11258999068719890",
		name: "Gambia",
		iso3166_a2: "GM",
		dial_code: "220"
	},
	{
		id: "11258999068719891",
		name: "Guinea",
		iso3166_a2: "GN",
		dial_code: "224"
	},
	{
		id: "11258999068719892",
		name: "Guadeloupe",
		iso3166_a2: "GP",
		dial_code: "590"
	},
	{
		id: "11258999068719893",
		name: "Equatorial Guinea",
		iso3166_a2: "GQ",
		dial_code: "240"
	},
	{
		id: "11258999068719894",
		name: "Greece",
		iso3166_a2: "GR",
		dial_code: "30"
	},
	{
		id: "11258999068719895",
		name: "South Georgia and the South Sandwich Islands",
		iso3166_a2: "GS",
		dial_code: "500"
	},
	{
		id: "11258999068719896",
		name: "Guatemala",
		iso3166_a2: "GT",
		dial_code: "502"
	},
	{
		id: "11258999068719897",
		name: "Guam",
		iso3166_a2: "GU",
		dial_code: "1"
	},
	{
		id: "11258999068719898",
		name: "Guinea-Bissau",
		iso3166_a2: "GW",
		dial_code: "245"
	},
	{
		id: "11258999068719899",
		name: "Guyana",
		iso3166_a2: "GY",
		dial_code: "592"
	},
	{
		id: "11258999068719900",
		name: "Hong Kong",
		iso3166_a2: "HK",
		dial_code: "852"
	},
	{
		id: "11258999068719901",
		name: "Honduras",
		iso3166_a2: "HN",
		dial_code: "504"
	},
	{
		id: "11258999068719902",
		name: "Croatia",
		iso3166_a2: "HR",
		dial_code: "385"
	},
	{
		id: "11258999068719903",
		name: "Haiti",
		iso3166_a2: "HT",
		dial_code: "509"
	},
	{
		id: "11258999068719904",
		name: "Hungary",
		iso3166_a2: "HU",
		dial_code: "36"
	},
	{
		id: "11258999068719905",
		name: "Indonesia",
		iso3166_a2: "ID",
		dial_code: "62"
	},
	{
		id: "11258999068719906",
		name: "Ireland",
		iso3166_a2: "IE",
		dial_code: "353"
	},
	{
		id: "11258999068719907",
		name: "Israel",
		iso3166_a2: "IL",
		dial_code: "972"
	},
	{
		id: "11258999068719908",
		name: "Isle of Man",
		iso3166_a2: "IM",
		dial_code: "44"
	},
	{
		id: "11258999068719909",
		name: "India",
		iso3166_a2: "IN",
		dial_code: "91"
	},
	{
		id: "11258999068719910",
		name: "British Indian Ocean Territory",
		iso3166_a2: "IO",
		dial_code: "246"
	},
	{
		id: "11258999068719911",
		name: "Iraq",
		iso3166_a2: "IQ",
		dial_code: "964"
	},
	{
		id: "11258999068719912",
		name: "Iran",
		iso3166_a2: "IR",
		dial_code: "98"
	},
	{
		id: "11258999068719913",
		name: "Iceland",
		iso3166_a2: "IS",
		dial_code: "354"
	},
	{
		id: "11258999068719914",
		name: "Italy",
		iso3166_a2: "IT",
		dial_code: "39"
	},
	{
		id: "11258999068719915",
		name: "Jersey",
		iso3166_a2: "JE",
		dial_code: "44"
	},
	{
		id: "11258999068719916",
		name: "Jamaica",
		iso3166_a2: "JM",
		dial_code: "1"
	},
	{
		id: "11258999068719917",
		name: "Jordan",
		iso3166_a2: "JO",
		dial_code: "962"
	},
	{
		id: "11258999068719918",
		name: "Japan",
		iso3166_a2: "JP",
		dial_code: "81"
	},
	{
		id: "11258999068719919",
		name: "Kenya",
		iso3166_a2: "KE",
		dial_code: "254"
	},
	{
		id: "11258999068719920",
		name: "Kyrgyzstan",
		iso3166_a2: "KG",
		dial_code: "996"
	},
	{
		id: "11258999068719921",
		name: "Cambodia",
		iso3166_a2: "KH",
		dial_code: "855"
	},
	{
		id: "11258999068719922",
		name: "Kiribati",
		iso3166_a2: "KI",
		dial_code: "686"
	},
	{
		id: "11258999068719923",
		name: "Comoros",
		iso3166_a2: "KM",
		dial_code: "269"
	},
	{
		id: "11258999068719924",
		name: "Saint Kitts and Nevis",
		iso3166_a2: "KN",
		dial_code: "1"
	},
	{
		id: "11258999068719925",
		name: "North Korea",
		iso3166_a2: "KP",
		dial_code: "850"
	},
	{
		id: "11258999068719926",
		name: "South Korea",
		iso3166_a2: "KR",
		dial_code: "82"
	},
	{
		id: "11258999068719927",
		name: "Kuwait",
		iso3166_a2: "KW",
		dial_code: "965"
	},
	{
		id: "11258999068719928",
		name: "Cayman Islands",
		iso3166_a2: "KY",
		dial_code: "1"
	},
	{
		id: "11258999068719929",
		name: "Kazakhstan",
		iso3166_a2: "KZ",
		dial_code: "7"
	},
	{
		id: "11258999068719930",
		name: "Laos",
		iso3166_a2: "LA",
		dial_code: "856"
	},
	{
		id: "11258999068719931",
		name: "Lebanon",
		iso3166_a2: "LB",
		dial_code: "961"
	},
	{
		id: "11258999068719932",
		name: "Saint Lucia",
		iso3166_a2: "LC",
		dial_code: "1"
	},
	{
		id: "11258999068719933",
		name: "Liechtenstein",
		iso3166_a2: "LI",
		dial_code: "423"
	},
	{
		id: "11258999068719934",
		name: "Sri Lanka",
		iso3166_a2: "LK",
		dial_code: "94"
	},
	{
		id: "11258999068719935",
		name: "Liberia",
		iso3166_a2: "LR",
		dial_code: "231"
	},
	{
		id: "11258999068719936",
		name: "Lesotho",
		iso3166_a2: "LS",
		dial_code: "266"
	},
	{
		id: "11258999068719937",
		name: "Lithuania",
		iso3166_a2: "LT",
		dial_code: "370"
	},
	{
		id: "11258999068719938",
		name: "Luxembourg",
		iso3166_a2: "LU",
		dial_code: "352"
	},
	{
		id: "11258999068719939",
		name: "Latvia",
		iso3166_a2: "LV",
		dial_code: "371"
	},
	{
		id: "11258999068719940",
		name: "Libya",
		iso3166_a2: "LY",
		dial_code: "218"
	},
	{
		id: "11258999068719941",
		name: "Morocco",
		iso3166_a2: "MA",
		dial_code: "212"
	},
	{
		id: "11258999068719942",
		name: "Monaco",
		iso3166_a2: "MC",
		dial_code: "377"
	},
	{
		id: "11258999068719943",
		name: "Moldova",
		iso3166_a2: "MD",
		dial_code: "373"
	},
	{
		id: "11258999068719944",
		name: "Montenegro",
		iso3166_a2: "ME",
		dial_code: "382"
	},
	{
		id: "11258999068719945",
		name: "Saint-Martin (French)",
		iso3166_a2: "MF",
		dial_code: "590"
	},
	{
		id: "11258999068719946",
		name: "Madagascar",
		iso3166_a2: "MG",
		dial_code: "261"
	},
	{
		id: "11258999068719947",
		name: "Marshall Islands",
		iso3166_a2: "MH",
		dial_code: "692"
	},
	{
		id: "11258999068719948",
		name: "North Macedonia",
		iso3166_a2: "MK",
		dial_code: "389"
	},
	{
		id: "11258999068719949",
		name: "Mali",
		iso3166_a2: "ML",
		dial_code: "223"
	},
	{
		id: "11258999068719950",
		name: "Myanmar",
		iso3166_a2: "MM",
		dial_code: "95"
	},
	{
		id: "11258999068719951",
		name: "Mongolia",
		iso3166_a2: "MN",
		dial_code: "976"
	},
	{
		id: "11258999068719952",
		name: "Macao",
		iso3166_a2: "MO",
		dial_code: "853"
	},
	{
		id: "11258999068719953",
		name: "Northern Mariana Islands",
		iso3166_a2: "MP",
		dial_code: "1"
	},
	{
		id: "11258999068719954",
		name: "Martinique",
		iso3166_a2: "MQ",
		dial_code: "596"
	},
	{
		id: "11258999068719955",
		name: "Mauritania",
		iso3166_a2: "MR",
		dial_code: "222"
	},
	{
		id: "11258999068719956",
		name: "Montserrat",
		iso3166_a2: "MS",
		dial_code: "1"
	},
	{
		id: "11258999068719957",
		name: "Malta",
		iso3166_a2: "MT",
		dial_code: "356"
	},
	{
		id: "11258999068719958",
		name: "Mauritius",
		iso3166_a2: "MU",
		dial_code: "230"
	},
	{
		id: "11258999068719959",
		name: "Maldives",
		iso3166_a2: "MV",
		dial_code: "960"
	},
	{
		id: "11258999068719960",
		name: "Malawi",
		iso3166_a2: "MW",
		dial_code: "265"
	},
	{
		id: "11258999068719961",
		name: "Mexico",
		iso3166_a2: "MX",
		dial_code: "52"
	},
	{
		id: "11258999068719962",
		name: "Malaysia",
		iso3166_a2: "MY",
		dial_code: "60"
	},
	{
		id: "11258999068719963",
		name: "Mozambique",
		iso3166_a2: "MZ",
		dial_code: "258"
	},
	{
		id: "11258999068719964",
		name: "Namibia",
		iso3166_a2: "NA",
		dial_code: "264"
	},
	{
		id: "11258999068719965",
		name: "New Caledonia",
		iso3166_a2: "NC",
		dial_code: "687"
	},
	{
		id: "11258999068719966",
		name: "Niger",
		iso3166_a2: "NE",
		dial_code: "227"
	},
	{
		id: "11258999068719967",
		name: "Norfolk Island",
		iso3166_a2: "NF",
		dial_code: "672"
	},
	{
		id: "11258999068719968",
		name: "Nigeria",
		iso3166_a2: "NG",
		dial_code: "234"
	},
	{
		id: "11258999068719969",
		name: "Nicaragua",
		iso3166_a2: "NI",
		dial_code: "505"
	},
	{
		id: "11258999068719970",
		name: "Netherlands",
		iso3166_a2: "NL",
		dial_code: "31"
	},
	{
		id: "11258999068719971",
		name: "Norway",
		iso3166_a2: "NO",
		dial_code: "47"
	},
	{
		id: "11258999068719972",
		name: "Nepal",
		iso3166_a2: "NP",
		dial_code: "977"
	},
	{
		id: "11258999068719973",
		name: "Nauru",
		iso3166_a2: "NR",
		dial_code: "674"
	},
	{
		id: "11258999068719974",
		name: "Niue",
		iso3166_a2: "NU",
		dial_code: "683"
	},
	{
		id: "11258999068719975",
		name: "New Zealand",
		iso3166_a2: "NZ",
		dial_code: "64"
	},
	{
		id: "11258999068719976",
		name: "Oman",
		iso3166_a2: "OM",
		dial_code: "968"
	},
	{
		id: "11258999068719977",
		name: "Panama",
		iso3166_a2: "PA",
		dial_code: "507"
	},
	{
		id: "11258999068719978",
		name: "Peru",
		iso3166_a2: "PE",
		dial_code: "51"
	},
	{
		id: "11258999068719979",
		name: "French Polynesia",
		iso3166_a2: "PF",
		dial_code: "689"
	},
	{
		id: "11258999068719980",
		name: "Papua New Guinea",
		iso3166_a2: "PG",
		dial_code: "675"
	},
	{
		id: "11258999068719981",
		name: "Philippines",
		iso3166_a2: "PH",
		dial_code: "63"
	},
	{
		id: "11258999068719982",
		name: "Pakistan",
		iso3166_a2: "PK",
		dial_code: "92"
	},
	{
		id: "11258999068719983",
		name: "Poland",
		iso3166_a2: "PL",
		dial_code: "48"
	},
	{
		id: "11258999068719984",
		name: "Saint Pierre and Miquelon",
		iso3166_a2: "PM",
		dial_code: "508"
	},
	{
		id: "11258999068719985",
		name: "Pitcairn",
		iso3166_a2: "PN",
		dial_code: "64"
	},
	{
		id: "11258999068719986",
		name: "Puerto Rico",
		iso3166_a2: "PR",
		dial_code: "1"
	},
	{
		id: "11258999068719987",
		name: "Palestinian Territory",
		iso3166_a2: "PS",
		dial_code: "970"
	},
	{
		id: "11258999068719988",
		name: "Portugal",
		iso3166_a2: "PT",
		dial_code: "351"
	},
	{
		id: "11258999068719989",
		name: "Palau",
		iso3166_a2: "PW",
		dial_code: "680"
	},
	{
		id: "11258999068719990",
		name: "Paraguay",
		iso3166_a2: "PY",
		dial_code: "595"
	},
	{
		id: "11258999068719991",
		name: "Qatar",
		iso3166_a2: "QA",
		dial_code: "974"
	},
	{
		id: "11258999068719992",
		name: "Réunion",
		iso3166_a2: "RE",
		dial_code: "262"
	},
	{
		id: "11258999068719993",
		name: "Romania",
		iso3166_a2: "RO",
		dial_code: "40"
	},
	{
		id: "11258999068719994",
		name: "Serbia",
		iso3166_a2: "RS",
		dial_code: "381"
	},
	{
		id: "11258999068719995",
		name: "Russian Federation",
		iso3166_a2: "RU",
		dial_code: "7"
	},
	{
		id: "11258999068719996",
		name: "Rwanda",
		iso3166_a2: "RW",
		dial_code: "250"
	},
	{
		id: "11258999068719997",
		name: "Saudi Arabia",
		iso3166_a2: "SA",
		dial_code: "966"
	},
	{
		id: "11258999068719998",
		name: "Solomon Islands",
		iso3166_a2: "SB",
		dial_code: "677"
	},
	{
		id: "11258999068719999",
		name: "Seychelles",
		iso3166_a2: "SC",
		dial_code: "248"
	},
	{
		id: "11258999068720000",
		name: "Sudan",
		iso3166_a2: "SD",
		dial_code: "249"
	},
	{
		id: "11258999068720001",
		name: "Sweden",
		iso3166_a2: "SE",
		dial_code: "46"
	},
	{
		id: "11258999068720002",
		name: "Singapore",
		iso3166_a2: "SG",
		dial_code: "65"
	},
	{
		id: "11258999068720003",
		name: "Saint Helena",
		iso3166_a2: "SH",
		dial_code: "290"
	},
	{
		id: "11258999068720004",
		name: "Slovenia",
		iso3166_a2: "SI",
		dial_code: "386"
	},
	{
		id: "11258999068720005",
		name: "Svalbard and Jan Mayen Islands",
		iso3166_a2: "SJ",
		dial_code: "47"
	},
	{
		id: "11258999068720006",
		name: "Slovakia",
		iso3166_a2: "SK",
		dial_code: "421"
	},
	{
		id: "11258999068720007",
		name: "Sierra Leone",
		iso3166_a2: "SL",
		dial_code: "232"
	},
	{
		id: "11258999068720008",
		name: "San Marino",
		iso3166_a2: "SM",
		dial_code: "378"
	},
	{
		id: "11258999068720009",
		name: "Senegal",
		iso3166_a2: "SN",
		dial_code: "221"
	},
	{
		id: "11258999068720010",
		name: "Somalia",
		iso3166_a2: "SO",
		dial_code: "252"
	},
	{
		id: "11258999068720011",
		name: "Suriname",
		iso3166_a2: "SR",
		dial_code: "597"
	},
	{
		id: "11258999068720012",
		name: "South Sudan",
		iso3166_a2: "SS",
		dial_code: "211"
	},
	{
		id: "11258999068720013",
		name: "Sao Tome and Principe",
		iso3166_a2: "ST",
		dial_code: "239"
	},
	{
		id: "11258999068720014",
		name: "El Salvador",
		iso3166_a2: "SV",
		dial_code: "503"
	},
	{
		id: "11258999068720015",
		name: "Sint Maarten (Dutch)",
		iso3166_a2: "SX",
		dial_code: "1"
	},
	{
		id: "11258999068720016",
		name: "Syria",
		iso3166_a2: "SY",
		dial_code: "963"
	},
	{
		id: "11258999068720017",
		name: "Eswatini",
		iso3166_a2: "SZ",
		dial_code: "268"
	},
	{
		id: "11258999068720018",
		name: "Turks and Caicos Islands",
		iso3166_a2: "TC",
		dial_code: "1"
	},
	{
		id: "11258999068720019",
		name: "Chad",
		iso3166_a2: "TD",
		dial_code: "235"
	},
	{
		id: "11258999068720020",
		name: "Togo",
		iso3166_a2: "TG",
		dial_code: "228"
	},
	{
		id: "11258999068720021",
		name: "Thailand",
		iso3166_a2: "TH",
		dial_code: "66"
	},
	{
		id: "11258999068720022",
		name: "Tajikistan",
		iso3166_a2: "TJ",
		dial_code: "992"
	},
	{
		id: "11258999068720023",
		name: "Tokelau",
		iso3166_a2: "TK",
		dial_code: "690"
	},
	{
		id: "11258999068720024",
		name: "Timor-Leste",
		iso3166_a2: "TL",
		dial_code: "670"
	},
	{
		id: "11258999068720025",
		name: "Turkmenistan",
		iso3166_a2: "TM",
		dial_code: "993"
	},
	{
		id: "11258999068720026",
		name: "Tunisia",
		iso3166_a2: "TN",
		dial_code: "216"
	},
	{
		id: "11258999068720027",
		name: "Tonga",
		iso3166_a2: "TO",
		dial_code: "676"
	},
	{
		id: "11258999068720028",
		name: "Turkey",
		iso3166_a2: "TR",
		dial_code: "90"
	},
	{
		id: "11258999068720029",
		name: "Trinidad and Tobago",
		iso3166_a2: "TT",
		dial_code: "1"
	},
	{
		id: "11258999068720030",
		name: "Tuvalu",
		iso3166_a2: "TV",
		dial_code: "688"
	},
	{
		id: "11258999068720031",
		name: "Taiwan",
		iso3166_a2: "TW",
		dial_code: "886"
	},
	{
		id: "11258999068720032",
		name: "Tanzania",
		iso3166_a2: "TZ",
		dial_code: "255"
	},
	{
		id: "11258999068720033",
		name: "Ukraine",
		iso3166_a2: "UA",
		dial_code: "380"
	},
	{
		id: "11258999068720034",
		name: "Uganda",
		iso3166_a2: "UG",
		dial_code: "256"
	},
	{
		id: "11258999068720035",
		name: "United States of America",
		iso3166_a2: "US",
		dial_code: "1"
	},
	{
		id: "11258999068720036",
		name: "Uruguay",
		iso3166_a2: "UY",
		dial_code: "598"
	},
	{
		id: "11258999068720037",
		name: "Uzbekistan",
		iso3166_a2: "UZ",
		dial_code: "998"
	},
	{
		id: "11258999068720038",
		name: "Vatican City",
		iso3166_a2: "VA",
		dial_code: "39"
	},
	{
		id: "11258999068720039",
		name: "Saint Vincent and Grenadines",
		iso3166_a2: "VC",
		dial_code: "1"
	},
	{
		id: "11258999068720040",
		name: "Venezuela",
		iso3166_a2: "VE",
		dial_code: "58"
	},
	{
		id: "11258999068720041",
		name: "Virgin Islands (British)",
		iso3166_a2: "VG",
		dial_code: "1"
	},
	{
		id: "11258999068720042",
		name: "Virgin Islands (U.S.)",
		iso3166_a2: "VI",
		dial_code: "1"
	},
	{
		id: "11258999068720043",
		name: "Vietnam",
		iso3166_a2: "VN",
		dial_code: "84"
	},
	{
		id: "11258999068720044",
		name: "Vanuatu",
		iso3166_a2: "VU",
		dial_code: "678"
	},
	{
		id: "11258999068720045",
		name: "Wallis and Futuna Islands",
		iso3166_a2: "WF",
		dial_code: "681"
	},
	{
		id: "11258999068720046",
		name: "Samoa",
		iso3166_a2: "WS",
		dial_code: "685"
	},
	{
		id: "11258999068720047",
		name: "Yemen",
		iso3166_a2: "YE",
		dial_code: "967"
	},
	{
		id: "11258999068720048",
		name: "Mayotte",
		iso3166_a2: "YT",
		dial_code: "262"
	},
	{
		id: "11258999068720049",
		name: "South Africa",
		iso3166_a2: "ZA",
		dial_code: "27"
	},
	{
		id: "11258999068720050",
		name: "Zambia",
		iso3166_a2: "ZM",
		dial_code: "260"
	},
	{
		id: "11258999068720051",
		name: "Zimbabwe",
		iso3166_a2: "ZW",
		dial_code: "263"
	},
	{
		id: "11258999068720052",
		name: "Ivory Coast",
		iso3166_a2: "CI",
		dial_code: "225"
	},
	{
		id: "11258999068720053",
		name: "Kosovo",
		iso3166_a2: "XK",
		dial_code: "383"
	}
]