// src/lib/stores/countriesStore.ts
import { writable } from 'svelte/store';
import { browser } from '$app/environment';
import { fallbackCountries } from '$lib/data/fallback_countries';

// Define the Country interface (can be moved to a shared types file later if needed)
export interface Country {
	id: string;
	name: string;
	iso3166_a2: string; // ISO 3166-1 alpha-2 code (e.g., 'US')
	dial_code: string; // e.g., '+1'
}

// Function to get initial data, reading from localStorage if available
function getInitialCountries(): Country[] {
	if (browser) {
		const storedCountries = localStorage.getItem('countries');
		if (storedCountries) {
			try {
				const parsed = JSON.parse(storedCountries);
				// Basic validation to ensure it's an array
				if (Array.isArray(parsed)) {
					return parsed;
				}
			} catch (e) {
				console.error("Error parsing countries from localStorage", e);
				localStorage.removeItem('countries'); // Clear invalid data
			}
		} else {
			console.log("Using fallback countries");
			return fallbackCountries
		}
	}
	return []; // Default to empty array if not in browser or not found/invalid
}

// Create the writable store, initialized from localStorage
const store = writable<Country[]>(getInitialCountries());

// Subscribe to changes and update localStorage
if (browser) {
	store.subscribe(value => {
		try {
			localStorage.setItem('countries', JSON.stringify(value));
		} catch (e) {
			console.error("Error saving countries to localStorage", e);
		}
	});
}

// Export the store
export const countries = store;